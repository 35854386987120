import React from "react";
import Cookies from 'js-cookie'
import 'bootstrap/dist/css/bootstrap.css';
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton';
import { contactedOptions, bookingOptions } from '../components/Inventory.js'
import { Accordion, Card, Form, InputGroup, FormControl } from "react-bootstrap";

export default class PricingTable extends React.Component {

    state = {

        itemLocation: "Select Location...",
        itemAgent: this.props.item.AGENT,
        itemStatus: "Select Status...",
        itemLeadNumber: this.props.item["INVNUM"],
        dealStatusText: this.props.item.DEAL,
        saveButtonText: "SAVE",
        itemSubmissions: [],
        itemYear: this.props.item.YEAR,
        itemMake: this.props.item.MAKE,
        itemModel: this.props.item.MODEL,
        itemTrim: this.props.item.TRIM,
        itemColor: this.props.item.COLOR,
        itemMisc: "",
        askingPriceInput: this.props.item.ASKINGPRICE,
        itemOffer: this.props.item.OUROFFER,
        itemRecon: this.props.item.RECONUSD,
        itemVINInput: this.props.item.VIN,
        itemPhotosLink: this.props.item.PHOTOSLINK,
        itemSellerName: this.props.item.SELLERNAME,
        itemSellerPhone: this.props.item.SELLERPHONE,
        itemNotes: this.props.item.INTERNALNOTES,
        itemExteriorDamages: this.props.item.EXTERIORDAMAGES,
        itemInteriorDamages: this.props.item.INTERIORDAMAGES,
        itemOptions: this.props.item.OPTIONS,
        sellerNotes: this.props.item.SELLERNOTES,
        itemDate: this.props.item.LISTDATE,
        itemListingLink: this.props.item["LISTINGLINK"],

        itemKM: this.props.item["KMs"],
        itemMI: this.props.item["MILES"],

        itemTireFL: this.props.item.FLTIRE,
        itemTireFR: this.props.item.FRTIRE,
        itemTireRR: this.props.item.RRTIRE,
        itemTireRL: this.props.item.RLTIRE,

        itemDesc: this.props.item.DESC,
        itemPurchasePrice: this.props.item.PURCHASEPRICE,
        itemPurchaseDate: this.props.item.PURCHASEDATE,
        itemACV: this.props.item.ACV,

        itemSalePrice: this.props.item.SALEPRICE,
        itemSaleDate: this.props.item.SALEDATE,
        itemSoldTo: this.props.item.SOLDTO,

        itemJDPOWERcalc: '',
        itemKBBcalc: '',
        itemMMRcalc: '',
        itemPIPELINEcalc: '',
        itemTRADEREVcalc: '',
        itemEBLOCKcalc: '',
        itemADESSAcalc: '',
        itemCDNRMTGcalc: '',
        itemCBBcalc: '',

        itemJDPOWER: this.props.item.JDPOWER,
        itemKBB: this.props.item.KBB,
        itemMMR: this.props.item.MMR,
        itemPIPELINE: this.props.item.PIPELINE,
        itemTRADEREV: this.props.item.TRADEREV,
        itemEBLOCK: this.props.item.EBLOCK,
        itemADESSA: this.props.item.ADESSA,
        itemCDNRMTG: this.props.item.CDNRMKTG,
        itemCBB: this.props.item.CBB,
        itemCBLB: this.props.item.CBLB,

        itemJDPlink: this.props.item.ITEMJBPLINK,
        itemKBBlink: this.props.item.ITEMKBBLINK,
        itemMMRlink: this.props.item.ITEMMMRLINK,
        itemPIPElink: this.props.item.ITEMPIPELINK,
        itemTREVlink: this.props.item.ITEMTREVLINK,
        itemEBLKlink: this.props.item.ITEMEBLKLINK,
        itemADSAlink: this.props.item.ITEMADSALINK,
        itemCRMTlink: this.props.item.ITEMCRMTLINK,
        itemCBBlink: this.props.item.ITEMCBBLINK,
        itemCBLBlink: this.props.item.CBLB,

        usaRecon: this.props.item.RECONUSD,
        itemOPTIONS: this.props.item.OPTIONS,
        itemSELLERNOTES: this.props.item.SELLERNOTES,

        switchTestDrive: this.props.item["Test Drive & Inspection Form"],
        switchCarFax: this.props.item["CarFax Attained"],
        switchLienSearch: this.props.item["Lien Search"],
        switchPurchasingAgreement: this.props.item["Purchasing Agreement"],
        switchCustomerSignedCarFaxMFA: this.props.item["Customer Signed Carfax & MFA"],
        switchProofOfPayment: this.props.item["Proof of Payment"],
        switchLienReleasePaid: this.props.item["Lien Release Paid"],
        switchKeysAndRegAttained: this.props.item["Keys & Registration Attained"],
        switchRegistrationToNBM: this.props.item["Registration Transferred to NBM"],
        switchTransportation: this.props.item["Transportation Coordinated & Invoiced"],
        switchRegistrationSpareKeysSent: this.props.item["Registration & Spare Keys Sent"],
        switchFundingRequestedConfirmed: this.props.item["Funding Requested & Confirmed"],
        switchSellingAgreement: this.props.item["Selling Agreement / Bill of Sale Signed"],
        switchConvertedToInventory: this.props.item["Converted to Inventory"],
        switchInvoiceSent: this.props.item["Invoice Sent / Sales Receipt Completed"],
        switchListedOnWebsite: this.props.item["Listed on Website"],
        switch7501Attained: this.props.item["7501 Attained"],
      }

    componentDidMount() {
      if (this.props.item.ASKINGPRICE) {
        this.setState({
          itemAskingPrice: this.props.item.ASKINGPRICE
        })
      }

      if (!this.props.item.RECONUSD) {
        this.setState({
          usaRecon: 0
        })
      }
      
      if (!Cookies.get("usdtoCAD")) { 
        Cookies.set("usdtoCAD", 1.22)
        this.setState({ usdtoCAD: 1.22 })
      }
      if (!Cookies.get("waslProfit")) { 
        Cookies.set("waslProfit", 2250) 
        this.setState({ waslProfit: 2250 })
      }
      if (!Cookies.get("waslTransport")) { 
        Cookies.set("waslTransport", 0) 
        this.setState({ waslTransport: 0 })
      }
      if (!Cookies.get("waslHoldback")) { 
        Cookies.set("waslHoldback", 450)
        this.setState({ waslHoldback: 450 })
      }
      if (!Cookies.get("usaProfit")) { 
        Cookies.set("usaProfit", 2000) 
        this.setState({ usaProfit: 2000 })
      }
      if (!Cookies.get("usaTransport")) { 
        Cookies.set("usaTransport", 1850) 
        this.setState({ usaTransport: 1850 })
      }

      this.setState({
        usdtoCAD: parseFloat(Cookies.get("usdtoCAD")),
        waslProfit: parseFloat(Cookies.get("waslProfit")),
        waslTransport: parseFloat(Cookies.get("waslTransport")),
        waslHoldback: parseFloat(Cookies.get("waslHoldback")),
        usaProfit: parseFloat(Cookies.get("usaProfit")),
        usaTransport: parseFloat(Cookies.get("usaTransport"))
      })

      setTimeout(function() { //Start the timer
        this.updatePrices()
      }.bind(this), 1000)
    }

    calcMiles = (kms) => {
      var miles = kms / 1.6
      return miles.toFixed(0)
    }

    calcPrice = (value, currency, domestic) => {
        if (value) {
          value = this.toNum(value)

          if (currency == 'USD') {

            var usaOverhead = 0
            var cadOverhead = 0
            var cadRecon = (parseFloat(this.state.usdtoCAD) * parseFloat(this.state.usaRecon))
            var cadOverhead = parseFloat(this.state.waslTransport) + parseFloat(this.state.waslProfit) + parseFloat(this.state.waslHoldback) + cadRecon
            var cadValue = value * parseFloat(this.state.usdtoCAD)
            var cadValue = cadValue - cadOverhead
            var usaOverhead = parseFloat(this.state.usaTransport) + parseFloat(this.state.usaProfit)
            var usaOverhead = usaOverhead * parseFloat(this.state.usdtoCAD)  

            if (domestic) {
              var finalNum = cadValue
            } else {
              var finalNum = cadValue - usaOverhead
            }
          } else if (currency == 'CAD') {
            var cadRecon = parseFloat(this.state.usdtoCAD) * parseFloat(this.state.usaRecon)
            var cadOverhead = parseFloat(this.state.waslTransport) + parseFloat(this.state.waslProfit) + parseFloat(this.state.waslHoldback) + cadRecon

            var usaOverhead = parseFloat(this.state.usaTransport) + parseFloat(this.state.usaProfit)
            var usaOverhead = usaOverhead * parseFloat(this.state.usdtoCAD)  

            if (domestic) {
              var finalNum = value - cadOverhead
            } else {
              var beforeExport = value - cadOverhead
              var finalNum = beforeExport - usaOverhead
            }
          }
          return finalNum
        } else {
            return 0
        }
    }

    calcProfit = (value) => {
        if (value != null) {

        } else {
            return 0;
        }
    }

    // Create our number formatter.
    formatCurrency = (value) => {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        })

        return ( formatter.format(value) )
    }

    toNum = (value) => {
        if (value != null) {
            var result = value.replace(/[$,]+/g,"");
            return parseFloat(result)
        } else {
            return 0;
        }
    }

    updatePrices = () => {

        var expJDP = this.calcPrice(this.state.itemJDPOWER, 'USD')
        var expKBB = this.calcPrice(this.state.itemKBB, 'USD')
        var expMMR = this.calcPrice(this.state.itemMMR, 'USD')
        var expPIPE = this.calcPrice(this.state.itemPIPELINE, 'USD')
        var expTREV = this.calcPrice(this.state.itemTRADEREV, 'CAD')
        var expEBLK = this.calcPrice(this.state.itemEBLOCK, 'CAD')
        var expADSA = this.calcPrice(this.state.itemADESSA, 'CAD')
        var expCRMT = this.calcPrice(this.state.itemCDNRMTG, 'CAD')
        var expCBB = this.calcPrice(this.state.itemCBB, 'CAD')
        var expCBLB = this.calcPrice(this.state.itemCBLB, 'CAD')

        var domJDP = this.calcPrice(this.state.itemJDPOWER, 'USD', true)
        var domKBB = this.calcPrice(this.state.itemKBB, 'USD', true)
        var domMMR = this.calcPrice(this.state.itemMMR, 'USD', true)
        var domPIPE = this.calcPrice(this.state.itemPIPELINE, 'USD', true)
        var domTREV = this.calcPrice(this.state.itemTRADEREV, 'CAD', true)
        var domEBLK = this.calcPrice(this.state.itemEBLOCK, 'CAD', true)
        var domADSA = this.calcPrice(this.state.itemADESSA, 'CAD', true)
        var domCRMT = this.calcPrice(this.state.itemCDNRMTG, 'CAD', true)
        var domCBB = this.calcPrice(this.state.itemCBB, 'CAD', true)
        var domCBLB = this.calcPrice(this.state.itemCBLB, 'CAD', true)

        this.setState({

            // calc Export Values

            itemJDPOWERcalc: this.formatCurrency(expJDP),
            itemKBBcalc: this.formatCurrency(expKBB),
            itemMMRcalc: this.formatCurrency(expMMR),
            itemPIPELINEcalc: this.formatCurrency(expPIPE),
            itemTRADEREVcalc: this.formatCurrency(expTREV),
            itemEBLOCKcalc: this.formatCurrency(expEBLK),
            itemADESSAcalc: this.formatCurrency(expADSA),
            itemCDNRMTGcalc: this.formatCurrency(expCRMT),
            itemCBBcalc: this.formatCurrency(expCBB),
            itemCBLBcalc: this.formatCurrency(expCBLB),

            exportAverage: this.calcAverage([
              expJDP,expKBB,expMMR,expPIPE,expTREV,expEBLK,expADSA,expCRMT,expCBB,expCBLB
            ]),

            // calc Domestic Values

            itemJDPdom: this.formatCurrency(domJDP),
            itemKBBdom: this.formatCurrency(domKBB),
            itemMMRdom: this.formatCurrency(domMMR),
            itemPIPEdom: this.formatCurrency(domPIPE),
            itemTREVdom: this.formatCurrency(domTREV),
            itemEBLKdom: this.formatCurrency(domEBLK),
            itemADSAdom: this.formatCurrency(domADSA),
            itemCRMTdom: this.formatCurrency(domCRMT),
            itemCBBdom: this.formatCurrency(domCBB),
            itemCBLBdom: this.formatCurrency(domCBLB),

            domesticAverage: this.calcAverage([
              domJDP,domKBB,domMMR,domPIPE,domTREV,domEBLK,domADSA,domCRMT,domCBB,domCBLB
            ])

        })

    }

    calcAverage = (inputArray) => {

      var sum = 0
      var count = 0
      var average = 0

      {inputArray.map( (input) => {
        if (input > 0) {
          sum = sum + input
          count = count + 1
          average = sum / count
        }
      }
      )}
      return this.formatCurrency(average)
    }

    updateClipboard = (newClip) => {
        navigator.clipboard.writeText(newClip).then(
            () => {
            console.log("Copied!");
            },
            () => {
            console.log("Copy failed!");
            }
        );
    }

    processLink = (link) => {
        if (link) {
            var str = link.substring(0, 4)
            if (str == 'http') {
                return link
            } else {
                return 'https://' + link
            }
        }
    }

    render () {
        return (
            <div className="section">
              <div className="row">
                <div className="col-md-8 itemInfoSection">
                  <div className="row">
                    <div className='col-md-3'>
                      <DropdownButton id="itemDealStatus" title={this.state.dealStatusText} className="m-1 w-100"> 
                        {bookingOptions.map((dealStatus) => 
                          <Dropdown.Item key={dealStatus} onClick={(e) => { this.setState({ dealStatusText: e.target.textContent}) }}>
                            {dealStatus}
                          </Dropdown.Item> )}
                      </DropdownButton>
                    </div>
                    <div className="col-md-3">
                      <DropdownButton id="itemAGENT" title={!this.state.itemAgent ? "Set Contacted..." : this.state.itemAgent} className="m-1 w-100"> 
                        {contactedOptions.map((contacted) => 
                        <Dropdown.Item key={contacted} onClick={(e) => { this.setState({ itemAgent: e.target.textContent }) }}>
                          {contacted}
                        </Dropdown.Item> )}
                      </DropdownButton>
                    </div>
                    <div className="col-md-3">
                      <strong>Date Added</strong><br />
                      <input value={this.state.itemDate} id="itemDate" className="item-input" onChange={e => { this.setState({ itemDate: e.target.value }); } } type='text' />
                    </div>
                    <div className="col-md-3">
                      <strong>Asking Price</strong><br />
                      <input value={this.state.askingPriceInput} id="askingPriceInput" className="item-input" onChange={e => { this.setState({ askingPriceInput: e.target.value }); } } type='text' />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <strong>Year</strong><br />
                      <input value={this.state.itemYear} id="itemYear" className="item-input" onChange={e => { this.setState({ itemYear: e.target.value }); } } type='text' />
                    </div>
                    <div className="col-md-3">
                      <strong>Make</strong><br />
                      <input value={this.state.itemMake} id="itemMake" className="item-input" onChange={e => { this.setState({ itemMake: e.target.value }); } } type='text' />
                    </div>
                    <div className="col-md-3">
                      <strong>Model</strong><br />
                      <input value={this.state.itemModel} id="itemModel" className="item-input" onChange={e => { this.setState({ itemModel: e.target.value }); } } type='text' />
                    </div>
                    <div className="col-md-3">
                      <strong>Trim</strong><br />
                      <input value={this.state.itemTrim} id="itemTrim" className="item-input" onChange={e => { this.setState({ itemTrim: e.target.value }); } } type='text' />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <strong>Color</strong><br />
                      <input value={this.state.itemColor} id="itemColor" className="item-input" onChange={e => { this.setState({ itemColor: e.target.value }); } } type='text' />
                    </div>
                    <div className="col-md-3">
                      <a href="#" onClick={(e) => { e.preventDefault(); this.updateClipboard( this.state.itemKM ); }}><strong>KMs</strong></a><br />
                      <input value={this.state.itemKM} id="itemKM" className="item-input" onChange={e => { this.setState({ itemKM: e.target.value }); this.setState({ itemMI: this.calcMiles(e.target.value)}) }} type='text' />
                    </div>
                    <div className="col-md-3">
                      <a href="#" onClick={(e) => { e.preventDefault(); this.updateClipboard( this.state.itemMI ); }}><strong>Miles</strong></a><br />
                      <input value={this.state.itemMI} id="itemMI" className="item-input" onChange={e => { this.setState({ itemMI: e.target.value }); } } type='text' />
                    </div>
                    <div className="col-md-3">
                      <strong>Recon(USD): </strong><br />
                      <input
                        id="estRecon"
                        className="item-input" 
                        defaultValue={this.state.usaRecon}
                        placeholder="Enter value..."
                        onChange={(e) => {
                          this.setState({ usaRecon: e.target.value }, () => {
                            this.updatePrices();
                          });
                        }}
                      ></input>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <table className="tirePressureTable">
                        <tbody>
                          <tr>
                            <td><strong>FLTR</strong></td>
                            <td><strong>FRTR</strong></td>
                            <td><strong>RRTR</strong></td>
                            <td><strong>RLTR</strong></td>
                          </tr>
                          <tr>
                            <td><input className='itemTireTreads' value={this.state.itemTireFL} id="itemTireFL" onChange={e => { this.setState({ itemTireFL: e.target.value }); } } type='text' /></td>
                            <td><input className='itemTireTreads' value={this.state.itemTireFR} id="itemTireFR" onChange={e => { this.setState({ itemTireFR: e.target.value }); } } type='text' /></td>
                            <td><input className='itemTireTreads' value={this.state.itemTireRR} id="itemTireRR" onChange={e => { this.setState({ itemTireRR: e.target.value }); } } type='text' /></td>
                            <td><input className='itemTireTreads' value={this.state.itemTireRL} id="itemTireRL" onChange={e => { this.setState({ itemTireRL: e.target.value }); } } type='text' /></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-3">
                      <a href="#" onClick={(e) => { e.preventDefault(); this.updateClipboard( this.state.itemVINInput ); }} ><strong>VIN</strong></a><br />
                      <input value={this.state.itemVINInput} id="itemVINInput" className="item-input" onChange={e => { this.setState({ itemVINInput: e.target.value }); } } type='text' />
                    </div>
                    <div className="col-md-3">
                      <a href={this.state.itemPhotosLink} target="_blank"><strong>Photos Link</strong></a><br />
                      <input value={this.state.itemPhotosLink} id="itemPhotosLink" className="item-input" onChange={e => { this.setState({ itemPhotosLink: e.target.value }); } } type='text' />
                    </div>
                    <div className="col-md-3">
                      <strong>Offer to Client</strong><br />
                      <input value={this.state.itemOffer} id="itemOffer" className="item-input" onChange={e => { this.setState({ itemOffer: e.target.value }); } } type='text' />
                    </div>
                  </div>
                  <div className="section accountingSection">
                    <div className="row">
                      <div className="col-md-3">
                        <strong>Purchase Price</strong><br />
                        <input value={this.state.itemPurchasePrice} id="itemPurchasePrice" className="item-input" onChange={e => { this.setState({ itemPurchasePrice: e.target.value }); } } type='text' />
                      </div>
                      <div className="col-md-3">
                        <strong>Purchase Date</strong><br />
                        <input value={this.state.itemPurchaseDate} id="itemPurchaseDate" className="item-input" onChange={e => { this.setState({ itemPurchaseDate: e.target.value }); } } type='text' />
                      </div>
                      <div className="col-md-3">
                        <strong>ACV</strong><br />
                        <input value={this.state.itemACV} id="itemACV" className="item-input" onChange={e => { this.setState({ itemACV: e.target.value }); } } type='text' />
                      </div>
                      <div className="col-md-3">
                        <strong>Seller's Name</strong><br />
                        <input value={this.state.itemSellerName} id="itemSellerName" className="item-input" onChange={e => { this.setState({ itemSellerName: e.target.value }); } } type='text' />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <strong>Sale Price</strong><br />
                        <input value={this.state.itemSalePrice} id="itemSalePrice" className="item-input" onChange={e => { this.setState({ itemSalePrice: e.target.value }); } } type='text' />
                      </div>
                      <div className="col-md-3">
                        <strong>Sale Date</strong><br />
                        <input value={this.state.itemSaleDate} id="itemSaleDate" className="item-input" onChange={e => { this.setState({ itemSaleDate: e.target.value }); } } type='text' />
                      </div>
                      <div className="col-md-3">
                        <strong>Sold To</strong><br />
                        <input value={this.state.itemSoldTo} id="itemSoldTo" className="item-input" onChange={e => { this.setState({ itemSoldTo: e.target.value }); } } type='text' />
                      </div>
                      <div className="col-md-3">
                        <strong>Phone Number</strong><br />
                        <input value={this.state.itemSellerPhone} id="itemSellerPhone" className="item-input" onChange={e => { this.setState({ itemSellerPhone: e.target.value }); } } type='text' />
                        <input value={this.state.itemListingLink} id="itemListingLink" className="item-input hidden" onChange={e => { this.setState({ itemListingLink: e.target.value }); } } type='text' />
                        <input value={this.state.itemLeadNumber} id="itemLeadNumber" className="item-input hidden" onChange={e => { this.setState({ itemLeadNumber: e.target.value }); } } type='text' />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 pricing-table">
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                          <strong>Pricing Table</strong>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                            {/* PRICING TABLE SECTION */}
                            <div className="section">
                              {/* PRICING TABLE ROW 1 - TITLES*/}
                              <div className="row">
                                <div className="col-3">
                                  <strong>Source</strong>
                                </div>
                                <div className="col-3">
                                  <strong>Value</strong>
                                </div>
                                <div className="col-3">
                                  <strong>Offer to Client</strong>
                                </div>
                                <div className="col-3">
                                  <strong>Link</strong>
                                </div>
                              </div>
                              {/* PRICING TABLE ROW 2 - JDP */}
                              <div className="row">
                                <div className="col-3">
                                  <strong><a target="_blank" href={"https://www.dealerslink.com/network/fastbook/valuator/index.cfm?vin=" + this.state.itemVINInput}>JDP[USD]</a></strong>
                                </div>
                                <div className="col-3">
                                  <input
                                        onChange={(e) => {
                                          this.setState(
                                            { itemJDPOWER: e.target.value },
                                            () => {
                                              this.updatePrices();
                                            }
                                          );
                                        }}
                                        type="text"
                                        className="dollarInput"
                                        defaultValue={this.state.itemJDPOWER}
                                        id="itemJDPOWER"
                                      />
                                </div>
                                <div className="col-3">
                                  <input id="itemJDPOWERcalc" value={this.state.itemJDPOWERcalc} className="dollarInput" readOnly />
                                </div>
                                <div className="col-3">
                                  <input
                                      id="itemJDPlink"
                                      defaultValue={this.state.itemJDPlink}
                                      className="dollarInput"
                                    />
                                </div>
                              </div>
                              {/* PRICING TABLE ROW 3 - KBB */}
                              <div className="row">
                                <div className="col-3">
                                  <strong><a target="_blank" href={"https://www.dealerslink.com/network/fastbook/valuator/index.cfm?vin=" + this.props.item.VIN}>KBB[USD]</a></strong>
                                </div>
                                <div className="col-3">
                                  <input
                                    onChange={(e) => {
                                      this.setState({ itemKBB: e.target.value }, () => {
                                        this.updatePrices();
                                      });
                                    }}
                                    type="text"
                                    className="dollarInput"
                                    defaultValue={this.props.item.KBB}
                                    id="itemKBB"
                                  />
                                </div>
                                <div className="col-3">
                                  <input
                                    id="itemKBBcalc"
                                    value={this.state.itemKBBcalc}
                                    className="dollarInput"
                                    readOnly
                                  />
                                </div>
                                <div className="col-3">
                                  <input
                                    id="itemKBBlink"
                                    defaultValue={this.state.itemKBBlink}
                                    className="dollarInput"
                                  />
                                </div>
                              </div>
                              {/* PRICING TABLE ROW 4 - MMR */}
                              <div className="row">
                                <div className="col-3">
                                  <strong><a target="_blank" href={"https://www.dealerslink.com/network/fastbook/valuator/index.cfm?vin=" + this.props.item.VIN}>MMR[USD]</a></strong>
                                </div>
                                <div className="col-3">
                                  <input
                                    onChange={(e) => {
                                      this.setState({ itemMMR: e.target.value }, () => {
                                        this.updatePrices();
                                      });
                                    }}
                                    type="text"
                                    className="dollarInput"
                                    defaultValue={this.props.item.MMR}
                                    id="itemMMR"
                                  />
                                </div>
                                <div className="col-3">
                                  <input
                                    id="itemMMRcalc"
                                    value={this.state.itemMMRcalc}
                                    className="dollarInput"
                                    readOnly
                                  />
                                </div>
                                <div className="col-3">
                                  <input
                                      id="itemMMRlink"
                                      defaultValue={this.state.itemMMRlink}
                                      className="dollarInput"
                                    />
                                </div>
                              </div>
                              {/* PRICING TABLE ROW 5 - PIPELINE */}
                              <div className="row">
                                <div className="col-3">
                                  <strong><a target="_blank" href="https://www.edgepipeline.com/components/market_report/view">PIPE[USD]</a></strong>
                                </div>
                                <div className="col-3">
                                  <input
                                    onChange={(e) => {
                                      this.setState(
                                        { itemPIPELINE: e.target.value },
                                        () => {
                                          this.updatePrices();
                                        }
                                      );
                                    }}
                                    type="text"
                                    className="dollarInput"
                                    defaultValue={this.props.item.PIPELINE}
                                    id="itemPIPELINE"
                                  />
                                </div>
                                <div className="col-3">
                                  <input
                                    id="itemPIPELINEcalc"
                                    value={this.state.itemPIPELINEcalc}
                                    className="dollarInput"
                                    readOnly
                                  />
                                </div>
                                <div className="col-3">
                                  <input
                                      id="itemPIPElink"
                                      defaultValue={this.state.itemPIPElink}
                                      className="dollarInput"
                                    />
                                </div>
                              </div>
                              {/* PRICING TABLE ROW 6 - TRADEREV */}
                              <div className="row">
                                <div className="col-3">
                                  <strong><a target="_blank" href="https://app.traderev.com/#!/research">TREV[CAD]</a></strong>
                                </div>
                                <div className="col-3">
                                  <input
                                    onChange={(e) => {
                                      this.setState(
                                        { itemTRADEREV: e.target.value },
                                        () => {
                                          this.updatePrices();
                                        }
                                      );
                                    }}
                                    type="text"
                                    className="dollarInput"
                                    defaultValue={this.props.item.TRADEREV}
                                    id="itemTRADEREV"
                                  />
                                </div>
                                <div className="col-3">
                                  <input
                                    id="itemTREVdom"
                                    value={this.state.itemTREVdom}
                                    className="dollarInput"
                                    readOnly
                                  />
                                </div>
                                <div className="col-3">
                                  <input
                                      id="itemTREVlink"
                                      defaultValue={this.state.itemTREVlink}
                                      className="dollarInput"
                                    />
                                </div>
                              </div>
                              {/* PRICING TABLE ROW 7 - EBLOCK */}
                              <div className="row">
                                <div className="col-3">
                                  <strong><a target="_blank" href="https://docs.google.com/spreadsheets/d/19KmeBCHtVkn6yrzcybEc4Jc0-nnnlTy3Kyfkbz5dPgY/edit#gid=1273417945">EBLK[CAD]</a></strong>
                                </div>
                                <div className="col-3">
                                  <input
                                    onChange={(e) => {
                                      this.setState(
                                        { itemEBLOCK: e.target.value },
                                        () => {
                                          this.updatePrices();
                                        }
                                      );
                                    }}
                                    type="text"
                                    className="dollarInput"
                                    defaultValue={this.props.item.EBLOCK}
                                    id="itemEBLOCK"
                                  />
                                </div>
                                <div className="col-3">
                                  <input
                                    id="itemEBLKdom"
                                    value={this.state.itemEBLKdom}
                                    className="dollarInput"
                                    readOnly
                                  />
                                </div>
                                <div className="col-3">
                                  <input
                                      id="itemEBLKlink"
                                      defaultValue={this.state.itemEBLKlink}
                                      className="dollarInput"
                                    />
                                </div>
                              </div>
                              {/* PRICING TABLE ROW 8 - ADESA */}
                              <div className="row">
                                <div className="col-3">
                                  <strong><a target="_blank" href="https://buy.adesa.ca/openauctionca/AMGCriteria.html">ADSA[CAD]</a></strong>
                                </div>
                                <div className="col-3">
                                  <input
                                    onChange={(e) => {
                                      this.setState(
                                        { itemADESSA: e.target.value },
                                        () => {
                                          this.updatePrices();
                                        }
                                      );
                                    }}
                                    type="text"
                                    className="dollarInput"
                                    defaultValue={this.props.item.ADESSA}
                                    id="itemADESSA"
                                  />
                                </div>
                                <div className="col-3">
                                  <input
                                    id="itemADSAdom"
                                    value={this.state.itemADSAdom}
                                    className="dollarInput"
                                    readOnly
                                  />
                                </div>
                                <div className="col-3">
                                  <input
                                      id="itemADSAlink"
                                      defaultValue={this.state.itemADSAlink}
                                      className="dollarInput"
                                    />
                                </div>
                              </div>
                              {/* PRICING TABLE ROW 9 - CANADIAN REMARKETING */}
                              <div className="row">
                                <div className="col-3">
                                <strong><a target="_blank" href="https://marketlens.kbb.ca/car-value/">CRMKT[CAD]</a></strong>
                                </div>
                                <div className="col-3">
                                  <input
                                    onChange={(e) => {
                                      this.setState(
                                        { itemCDNRMTG: e.target.value },
                                        () => {
                                          this.updatePrices();
                                        }
                                      );
                                    }}
                                    type="text"
                                    className="dollarInput"
                                    defaultValue={this.props.item.CDNRMKTG}
                                    id="itemCDNRMTG"
                                  />
                                </div>
                                <div className="col-3">
                                  <input
                                    id="itemCRMTdom"
                                    value={this.state.itemCRMTdom}
                                    className="dollarInput"
                                    readOnly
                                  />
                                </div>
                                <div className="col-3">
                                  <input
                                      id="itemCRMTlink"
                                      defaultValue={this.state.itemCRMTlink}
                                      className="dollarInput"
                                    />
                                </div>
                              </div>
                              {/* PRICING TABLE ROW 10 - CBB AUTOMOTIVE */}
                              <div className="row">
                                <div className="col-3">
                                  <strong><a target="_blank" href="https://online.blkbk.com/cbbonline/index.html">CBB[CAD]</a></strong>
                                </div>
                                <div className="col-3">
                                  <input
                                    onChange={(e) => {
                                      this.setState({ itemCBB: e.target.value }, () => {
                                        this.updatePrices();
                                      });
                                    }}
                                    type="text"
                                    className="dollarInput"
                                    defaultValue={this.props.item.CBB}
                                    id="itemCBB"
                                  />
                                </div>
                                <div className="col-3">
                                  <input
                                    id="itemCBBdom"
                                    value={this.state.itemCBBdom}
                                    className="dollarInput"
                                    readOnly
                                  />
                                </div>
                                <div className="col-3">
                                  <input
                                      id="itemCBBlink"
                                      defaultValue={this.state.itemCBBlink}
                                      className="dollarInput"
                                    />
                                </div>
                              </div>
                              {/* PRICING TABLE ROW 11 - CANADIAN BLUE BOOK MOTORSPORTS */}
                              <div className="row d-none">
                                <div className="col-3">
                                  <strong><a target="_blank" href="https://canadianbluebook.com/en-CA/home">CBLB[CAD]</a></strong>
                                </div>
                                <div className="col-3">
                                  <input
                                    onChange={(e) => {
                                      this.setState({ itemCBLB: e.target.value }, () => {
                                        this.updatePrices();
                                      });
                                    }}
                                    type="text"
                                    className="dollarInput"
                                    defaultValue={this.props.item.CBLB}
                                    id="itemCBLB"
                                  />
                                </div>
                                <div className="col-3">
                                  <input
                                    id="itemCBLBdom"
                                    value={this.state.itemCBLBdom}
                                    className="dollarInput"
                                    readOnly
                                  />
                                </div>
                                <div className="col-3">
                                  <input
                                      id="itemCBLBlink"
                                      defaultValue={this.state.itemCBLBlink}
                                      className="dollarInput"
                                    />
                                </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1">
                        <strong>Checklist (Initial When Completed)</strong>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <InputGroup><InputGroup.Text>Test Drive & Inspection Form</InputGroup.Text><FormControl id="switchTestDrive" defaultValue={this.state.switchTestDrive} /></InputGroup>
                          <InputGroup><InputGroup.Text>CarFax Attained</InputGroup.Text><FormControl id="switchCarFax" defaultValue={this.state.switchCarFax} /></InputGroup>
                          <InputGroup><InputGroup.Text>Lien Search</InputGroup.Text><FormControl id="switchLienSearch" defaultValue={this.state.switchLienSearch} /></InputGroup>
                          <InputGroup><InputGroup.Text>Purchasing Agreement / Bill of Sale</InputGroup.Text><FormControl id="switchPurchasingAgreement" defaultValue={this.state.switchPurchasingAgreement} /></InputGroup>
                          <InputGroup><InputGroup.Text>Customer Signed Carfax & MFA</InputGroup.Text><FormControl id="switchCustomerSignedCarFaxMFA" defaultValue={this.state.switchCustomerSignedCarFaxMFA} /></InputGroup>
                          <InputGroup><InputGroup.Text>Proof of Payment</InputGroup.Text><FormControl id="switchProofOfPayment" defaultValue={this.state.switchProofOfPayment} /></InputGroup>
                          <InputGroup><InputGroup.Text>Lien Release Paid</InputGroup.Text><FormControl id="switchLienReleasePaid" defaultValue={this.state.switchLienReleasePaid} /></InputGroup>
                          <InputGroup><InputGroup.Text>Keys & Registration Attained</InputGroup.Text><FormControl id="switchKeysAndRegAttained" defaultValue={this.state.switchKeysAndRegAttained} /></InputGroup>
                          <InputGroup><InputGroup.Text>Registration Transferred to NBM</InputGroup.Text><FormControl id="switchRegistrationToNBM" defaultValue={this.state.switchRegistrationToNBM} /></InputGroup>
                          <InputGroup><InputGroup.Text>Transportation Coordinated & Invoiced</InputGroup.Text><FormControl id="switchTransportation" defaultValue={this.state.switchTransportation} /></InputGroup>
                          <InputGroup><InputGroup.Text>Registration & Spare Keys Sent</InputGroup.Text><FormControl id="switchRegistrationSpareKeysSent" defaultValue={this.state.switchRegistrationSpareKeysSent} /></InputGroup>
                          <InputGroup><InputGroup.Text>Funding Requested & Confirmed</InputGroup.Text><FormControl id="switchFundingRequestedConfirmed" defaultValue={this.state.switchFundingRequestedConfirmed} /></InputGroup>
                          <InputGroup><InputGroup.Text>Selling Agreement / Bill of Sale</InputGroup.Text><FormControl id="switchSellingAgreement" defaultValue={this.state.switchSellingAgreement} /></InputGroup>
                          <InputGroup><InputGroup.Text>Converted to Inventory</InputGroup.Text><FormControl id="switchConvertedToInventory" defaultValue={this.state.switchConvertedToInventory} /></InputGroup>
                          <InputGroup><InputGroup.Text>Invoice Sent / Sales Receipt Completed</InputGroup.Text><FormControl id="switchInvoiceSent" defaultValue={this.state.switchInvoiceSent} /></InputGroup>
                          <InputGroup><InputGroup.Text>Listed on Website</InputGroup.Text><FormControl id="switchListedOnWebsite" defaultValue={this.state.switchListedOnWebsite} /></InputGroup>
                          <InputGroup><InputGroup.Text>7501 Attained</InputGroup.Text><FormControl id="switch7501Attained" defaultValue={this.state.switch7501Attained} /></InputGroup>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
              </div>
              <div>
                {/* VEHICLE INFORMATION DETAILS*/}
                <div className='row'>
                  <div className='col-md-2'>
                    <strong>Item Description</strong><br />
                    <textarea value={this.state.itemDesc} id="itemMiscNotes" onChange={e => { this.setState({ itemDesc: e.target.value }); } } className="textarea-internal-notes"></textarea>
                  </div>
                  <div className='col-md-2'>
                    <strong>Exterior Damages</strong><br />
                    <textarea value={this.state.itemExteriorDamages} id="itemExteriorDamages" onChange={e => { this.setState({ itemExteriorDamages: e.target.value }); } } className="textarea-internal-notes"></textarea>
                  </div>
                  <div className='col-md-2'>
                    <strong>Interior Damages</strong><br />
                    <textarea value={this.state.itemInteriorDamages} id="itemInteriorDamages" onChange={e => { this.setState({ itemInteriorDamages: e.target.value }); } } className="textarea-internal-notes"></textarea>
                  </div>
                  <div className='col-md-2'>
                    <strong>Internal Notes</strong><br />
                    <textarea value={this.state.itemNotes} id="itemNotes" onChange={e => { this.setState({ itemNotes: e.target.value }); } } className="textarea-internal-notes"></textarea>
                  </div>
                  <div className='col-md-2'>
                    <strong>Options</strong><br />
                    <textarea value={this.state.itemOptions} id="itemOptions" onChange={e => { this.setState({ itemOptions: e.target.value }); } } className="textarea-internal-notes" />
                  </div>
                  <div className='col-md-2'>
                    <strong>Evaluator's Notes</strong><br />
                    <textarea value={this.state.sellerNotes} id="sellerNotes" onChange={e => { this.setState({ sellerNotes: e.target.value }); } } className="textarea-internal-notes" />
                  </div>
                </div>
              </div>
              <div>
                {/* VARIABLES ROW - TITLES */}
                <div className="row">
                  <div className="col-md-2">
                    Exchange Rate
                    <input
                      id="exRate"
                      defaultValue={this.state.usdtoCAD}
                      placeholder="Enter value..."
                      className="overheadInput"
                      onChange={(e) => {
                        Cookies.set("usdtoCAD", e.target.value);
                        this.setState({ usdtoCAD: e.target.value }, () => {
                          this.updatePrices();
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <strong>Our Profit(CAD)</strong>
                    <input
                      id="waslProfit"
                      defaultValue={this.state.waslProfit}
                      placeholder="Enter value..."
                      className="overheadInput"
                      onChange={(e) => {
                        Cookies.set("waslProfit", e.target.value);
                        this.setState(
                          { waslProfit: e.target.value },
                          () => {
                            this.updatePrices();
                          }
                        );
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    Our Transport(CAD)
                    <input
                      id="waslTransport"
                      defaultValue={this.state.waslTransport}
                      placeholder="Enter value..."
                      className="overheadInput"
                      onChange={(e) => {
                        Cookies.set("waslTransport", e.target.value);
                        this.setState(
                          { waslTransport: e.target.value },
                          () => {
                            this.updatePrices();
                          }
                        );
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    Our Overhead(CAD)
                    <input
                      id="waslHoldback"
                      defaultValue={this.state.waslHoldback}
                      placeholder="Enter value..."
                      className="overheadInput"
                      onChange={(e) => {
                        Cookies.set("waslHoldback", e.target.value);
                        this.setState(
                          { waslHoldback: e.target.value },
                          () => {
                            this.updatePrices();
                          }
                        );
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    Buyer Profit(USD)
                    <input
                      id="usaProfit"
                      defaultValue={this.state.usaProfit}
                      placeholder="Enter value..."
                      className="overheadInput"
                      onChange={(e) => {
                        Cookies.set("usaProfit", e.target.value);
                        this.setState({ usaProfit: e.target.value }, () => {
                          this.updatePrices();
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    Buyer Transport(USD)
                    <input
                      id="usaTransport"
                      defaultValue={this.state.usaTransport}
                      placeholder="Enter value..."
                      className="overheadInput"
                      onChange={(e) => {
                        Cookies.set("usaTransport", e.target.value);
                        this.setState(
                          { usaTransport: e.target.value },
                          () => {
                            this.updatePrices();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
}