import React from 'react'
import Header from '../includes/header'
import Footer from '../includes/footer'
import { useEffect, useState, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton';
import Cookies from 'js-cookie'
import PricingTable from '../includes/pricingTable'
import UserStore from '../stores/UserStore'
import { Redirect } from 'react-router-dom';
import emailjs from '@emailjs/browser';
// import e from 'cors';

export const bookingOptions = ['Auto - Unbooked','Auto - Out of Scope','Moto - Unbooked','Moto - Out of Scope','Sent to Buyer','Pass','Contacted','Offer Sent','Review','Purchased','Follow Up','Lost']
export const locationOptions = ['Grande Prairie','Edmonton','Calgary','Red Deer','Peace River','Kelowna', 'Fort St. John', 'Cranbrook']
export const contactedOptions = ['Jeremy Hall','David Cavelle','Lori Debogorski','Andy Doucet', 'Chase Galbaransingh']
export const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const settingsPostingPlatforms = ['Kijiji', 'Facebook']

const ScraperLeads = () => {
    const currentUser = UserStore.getCurrentUser()
    const [data, setData] = useState()
    const accordionElement = useRef(null);
    const [saveButtonText, setSaveButtonText] = useState("SAVE")
    const [passButtonText, setPassButtonText] = useState("PASS")
    const [sendToBuyersText, setSendToBuyersText] = useState("Send To")
    const [passButtonDisabled, SetPassButtonDisabled] = useState("")

    const getAgentFilterCookie = () => Cookies.get('agentFilter') || "Filter by Agent..."
    const [currentAgent, setCurrentAgent] = useState(getAgentFilterCookie())
    function setAgentFilterCookie(agent) {
      setCurrentAgent(agent)
      Cookies.set('agentFilter', agent )
    }

    const getVINFilterCookie = () => Cookies.get('vinFilter') || "Filter by VIN..."
    const [currentVIN, setCurrentVIN] = useState(getVINFilterCookie())
    function setVINFilterCookie(vin) {
      setCurrentVIN(vin)
      Cookies.set('vinFilter', vin )
    }

    const getLocationCookie = () => Cookies.get('locationValue') || "Select Location"
    const [locationValue, setLocationValue] = useState(getLocationCookie());
    function setFieldLocation(location) {
      setLocationValue(location)
      Cookies.set('locationValue', location )
    }

    const getStatusCookie = () => Cookies.get('statusValue') || "Select Status";
    const [statusValue, setStatusValue] = useState(getStatusCookie());
    function setBookedStatus(status) {
      setStatusValue(status)
      Cookies.set('statusValue', status )
    }

    const [settingsBuyersNames, setSettingsBuyersNames] = useState("")

    const [buyerData, setBuyerData] = useState()

    const getData = async () => { 

      if ((locationValue !== "Select Location") && (statusValue !== "Select Status")) {

        try {
          const res = await fetch(
            "https://sheet.best/api/sheets/e5dfe255-956d-4351-803f-392b559fd864/tabs/BuyersList"
          )
          
          var buyerData = await res.json()

          var mappedData = Object.keys(buyerData).map((key) => buyerData[key])

          var buyerNames = mappedData.map(({Name}) => ( Name ))

          setSettingsBuyersNames(buyerNames)

          setBuyerData(mappedData)

        } catch (error) {
          console.log(error)
        }

        try {
          const res = await fetch(
            "https://sheet.best/api/sheets/e5dfe255-956d-4351-803f-392b559fd864/tabs/" + locationValue + "/DEAL/*" + statusValue + "*"
          )
          
          const data = await res.json()
          var mappedData = Object.keys(data).map((key) => data[key])

          if (currentAgent !== "Filter by Agent..." ) { 
            var mappedData = mappedData.filter((item) => item.AGENT.toLowerCase().includes(currentAgent.toLowerCase())) 
          }
          
          if (currentVIN !== "Filter by VIN..." ) { 
            var mappedData = mappedData.filter((item) => item.VIN.toLowerCase().includes(currentVIN.toLowerCase())) 
          }

          setData(mappedData)
          

        } catch (error) {
          console.log(error);
        }
      }
    };

    useEffect(() => {
      getData();
    }, []);

    // Create our number formatter.
    const formatCurrency = (value) => {
      var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
      })

      return ( formatter.format(value) )
    }

    function sendToBuyers(rowIndex, buyerName) {
      setSendToBuyersText("Sending...")
      const d = new Date();
      var dateMonth = monthNames[d.getMonth()]
      var dateMonthUppercase = monthNames[d.getMonth()].toUpperCase()

      var buyer = buyerData.filter((item) => item["Name"].includes(buyerName))

      var itemYear = document.querySelector('#collapse' + rowIndex).querySelector('#itemYear').value.toUpperCase()
      var itemMake = document.querySelector('#collapse' + rowIndex).querySelector('#itemMake').value.toUpperCase()
      var itemModelOptions = document.querySelector('#collapse' + rowIndex).querySelector('#itemModel').value + " " + document.querySelector('#collapse' + rowIndex).querySelector('#itemTrim').value + " | " + document.querySelector('#collapse' + rowIndex).querySelector('#itemOptions').value
      var itemColor = document.querySelector('#collapse' + rowIndex).querySelector('#itemColor').value.toUpperCase()
      var itemKM = document.querySelector('#collapse' + rowIndex).querySelector('#itemKM').value.toUpperCase()
      var itemMI = document.querySelector('#collapse' + rowIndex).querySelector('#itemMI').value.toUpperCase()
      var itemVIN = document.querySelector('#collapse' + rowIndex).querySelector('#itemVINInput').value.toUpperCase()
      var itemCanadianPrice = document.querySelector('#accordion-item' + rowIndex).querySelector('#itemBuyerPrice').value
      var itemPhotosLink = document.querySelector('#collapse' + rowIndex).querySelector('#itemPhotosLink').value
      var itemSellerNotes = document.querySelector('#collapse' + rowIndex).querySelector('#sellerNotes').value.toUpperCase()
      var itemAskingPrice = document.querySelector('#collapse' + rowIndex).querySelector('#askingPriceInput').value.toUpperCase()
      var itemListingLink = document.querySelector('#collapse' + rowIndex).querySelector('#itemListingLink').value
      var itemMiscNotes = document.querySelector('#collapse' + rowIndex).querySelector('#itemMiscNotes').value
      var itemLeadNumber = document.querySelector('#collapse' + rowIndex).querySelector('#itemLeadNumber').value
      var itemDate = document.querySelector('#collapse' + rowIndex).querySelector('#itemDate').value

      console.log(itemListingLink)
      
      if (buyer[0]["Spreadsheet"]) {
        var item = {}
        item["YEAR"] = itemYear
        item["MAKE"] = itemMake
        item["COLOR"] = itemColor
        item["KM"] = itemKM
        item["VIN"] = itemVIN
        if (buyer[0]["Name"] === "House Financial") {
          item["DATE"] = itemDate
          item["MODEL"] = itemModelOptions
          item["LEAD NUM"] = itemLeadNumber
          item["PRICE"] = itemAskingPrice
          item["PHOTOS"] = itemPhotosLink
          item["AD LINK"] = '=HYPERLINK("' + itemListingLink + '", "LINK")'
          item["SELLER NOTES"] = itemMiscNotes
        } else {
          item[dateMonthUppercase] = itemModelOptions
          item["CANADIAN"] = itemCanadianPrice
          item["RECON"] = itemPhotosLink
          item["SELLER NOTES"] = itemSellerNotes
          item["MILES"] = itemMI
        }

        var payload = JSON.stringify(item)

        fetch(buyer[0]["Spreadsheet"] + "/tabs/" + dateMonth,
            {
                method: "POST",
                mode: "cors",
                headers: {
                "Content-Type": "application/json",
            },
                body: payload
        })
        .then( response => {
          if (response.ok) {
            setSendToBuyersText("Sent to Sheet!")
            if (buyer[0]["Name"] === "House Financial") {
              fastHandler(itemLeadNumber, "Sent to Buyer")
              document.querySelector('#accordion-item' + rowIndex).toggleAttribute("hidden")
            }
          }
        })
        .catch( error => {
          setSendToBuyersText("Failed!")
          console.log("Error: " + error)
        })
      }

      if (buyer[0]["Email"]) {
        var templateParams = {
          year: document.querySelector('#collapse' + rowIndex).querySelector('#itemYear').value,
          make: document.querySelector('#collapse' + rowIndex).querySelector('#itemMake').value,
          modelequipment: document.querySelector('#collapse' + rowIndex).querySelector('#itemModel').value + " " + document.querySelector('#collapse' + rowIndex).querySelector('#itemTrim').value,
          color: document.querySelector('#collapse' + rowIndex).querySelector('#itemColor').value,
          kms: document.querySelector('#collapse' + rowIndex).querySelector('#itemKM').value,
          miles: document.querySelector('#collapse' + rowIndex).querySelector('#itemMI').value,
          vin: document.querySelector('#collapse' + rowIndex).querySelector('#itemVINInput').value,
          askingprice: itemCanadianPrice,
          photoslink: document.querySelector('#collapse' + rowIndex).querySelector('#itemPhotosLink').value,
          notes: document.querySelector('#collapse' + rowIndex).querySelector('#sellerNotes').value,
          buyers: buyer[0]["Email"]
        };

        emailjs.send('wasl-inventory-app', 'wasl-distribute', templateParams, 'vJL2doHGlcATTYc6N')
        .then(function(response) {
          console.log('SUCCESS!', response.status, response.text);
          setSendToBuyersText("Sent by Email!")
        }, function(error) {
          console.log('FAILED...', error);
          setSendToBuyersText("Failed!")
        });
      }

      if (buyer[0]["Phone"]) {

        console.log("Sending Text...")

        var message = {}

        message.to = buyer[0]["Phone"]
        message.body = "Hello, here is another lead from Northbound Motors" +
          "\nYear: " + itemYear +
          "\nMake: " + itemMake +
          "\nModel/Options: " + itemModelOptions +
          "\nColor: " + itemColor +
          "\nKMs: " + itemKM +
          "\nMiles: " + itemMI +
          "\nVIN: " + itemVIN +
          "\nPhotos Link: " + itemPhotosLink +
          "\nNotes about the Vehicle: " + itemSellerNotes +
          "\n\nPrice: " + itemCanadianPrice

        var payload = JSON.stringify(message);

        fetch('https://inventory.wholesalesolutions.ca:3002/api/messages', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: payload
        })
          .then(res => res.json())
          .then(data => {
            if (data.success) {
              setSendToBuyersText("Sent Text Message!")
            } else {
              setSendToBuyersText("Text Message Fail!")
              console.log(data)
            }
          });
      }

  }

    function saveData(rowIndex, invNum) {
        setSaveButtonText("SAVING...")

        fetch("https://sheet.best/api/sheets/e5dfe255-956d-4351-803f-392b559fd864/tabs/" + locationValue + "/INVNUM/*" + invNum,
            {
                method: "PATCH",
                mode: "cors",
                headers: {
                "Content-Type": "application/json",
            },
                body: JSON.stringify({
                  YEAR: document.querySelector('#collapse' + rowIndex).querySelector('#itemYear').value,
                  MAKE: document.querySelector('#collapse' + rowIndex).querySelector('#itemMake').value,
                  MODEL: document.querySelector('#collapse' + rowIndex).querySelector('#itemModel').value,
                  TRIM: document.querySelector('#collapse' + rowIndex).querySelector('#itemTrim').value,
                  COLOR: document.querySelector('#collapse' + rowIndex).querySelector('#itemColor').value,
                  DATE: document.querySelector('#collapse' + rowIndex).querySelector('#itemDate').value,
                  KMs: document.querySelector('#collapse' + rowIndex).querySelector('#itemKM').value,
                  PHOTOSLINK: document.querySelector('#collapse' + rowIndex).querySelector('#itemPhotosLink').value,
                  MILES: document.querySelector('#collapse' + rowIndex).querySelector('#itemMI').value,
                  FLTIRE: document.querySelector('#collapse' + rowIndex).querySelector('#itemTireFL').value,
                  FRTIRE: document.querySelector('#collapse' + rowIndex).querySelector('#itemTireFR').value,
                  RLTIRE: document.querySelector('#collapse' + rowIndex).querySelector('#itemTireRL').value,
                  RRTIRE: document.querySelector('#collapse' + rowIndex).querySelector('#itemTireRR').value,
                  JDPOWER: document.querySelector('#collapse' + rowIndex).querySelector('#itemJDPOWER').value,
                  KBB: document.querySelector('#collapse' + rowIndex).querySelector('#itemKBB').value,
                  MMR: document.querySelector('#collapse' + rowIndex).querySelector('#itemMMR').value,
                  PIPELINE: document.querySelector('#collapse' + rowIndex).querySelector('#itemPIPELINE').value,
                  TRADEREV: document.querySelector('#collapse' + rowIndex).querySelector('#itemTRADEREV').value,
                  EBLOCK: document.querySelector('#collapse' + rowIndex).querySelector('#itemEBLOCK').value,
                  ADESSA: document.querySelector('#collapse' + rowIndex).querySelector('#itemADESSA').value,
                  CDNRMKTG: document.querySelector('#collapse' + rowIndex).querySelector('#itemCDNRMTG').value,
                  CBB: document.querySelector('#collapse' + rowIndex).querySelector('#itemCBB').value,
                  AGENT: document.querySelector('#collapse' + rowIndex).querySelector('#itemAGENT').textContent,
                  SELLERNOTES: document.querySelector('#collapse' + rowIndex).querySelector('#sellerNotes').value,
                  OPTIONS: document.querySelector('#collapse' + rowIndex).querySelector('#itemOptions').value,
                  ASKINGPRICE: document.querySelector('#collapse' + rowIndex).querySelector('#askingPriceInput').value,
                  VIN: document.querySelector('#collapse' + rowIndex).querySelector('#itemVINInput').value,
                  OUROFFER: document.querySelector('#collapse' + rowIndex).querySelector('#itemOffer').value,
                  INTERNALNOTES: document.querySelector('#collapse' + rowIndex).querySelector('#itemNotes').value,
                  RECONUSD: document.querySelector('#collapse' + rowIndex).querySelector('#estRecon').value,
                  SENDTOBUYERPRICE: document.querySelector('#accordion-item' + rowIndex).querySelector('#itemBuyerPrice').value,
                  ACV: document.querySelector('#collapse' + rowIndex).querySelector('#itemACV').value,
                  PURCHASEPRICE: document.querySelector('#collapse' + rowIndex).querySelector('#itemPurchasePrice').value,
                  PURCHASEDATE: document.querySelector('#collapse' + rowIndex).querySelector('#itemPurchaseDate').value,
                  DEAL:  document.querySelector('#collapse' + rowIndex).querySelector('#itemDealStatus').innerHTML,
                  SALEPRICE: document.querySelector('#collapse' + rowIndex).querySelector('#itemSalePrice').value,
                  SALEDATE: document.querySelector('#collapse' + rowIndex).querySelector('#itemSaleDate').value,
                  SOLDTO: document.querySelector('#collapse' + rowIndex).querySelector('#itemSoldTo').value,
                  SELLERNAME: document.querySelector('#collapse' + rowIndex).querySelector('#itemSellerName').value,

                  // 								
                  ITEMJBPLINK: document.querySelector('#collapse' + rowIndex).querySelector('#itemJDPlink').value,
                  ITEMKBBLINK: document.querySelector('#collapse' + rowIndex).querySelector('#itemKBBlink').value,
                  ITEMMMRLINK: document.querySelector('#collapse' + rowIndex).querySelector('#itemMMRlink').value,
                  ITEMPIPELINK: document.querySelector('#collapse' + rowIndex).querySelector('#itemPIPElink').value,
                  ITEMTREVLINK: document.querySelector('#collapse' + rowIndex).querySelector('#itemTREVlink').value,
                  ITEMEBLKLINK: document.querySelector('#collapse' + rowIndex).querySelector('#itemEBLKlink').value,
                  ITEMADSALINK: document.querySelector('#collapse' + rowIndex).querySelector('#itemADSAlink').value,
                  ITEMCRMTLINK: document.querySelector('#collapse' + rowIndex).querySelector('#itemCRMTlink').value,
                  ITEMCBBLINK: document.querySelector('#collapse' + rowIndex).querySelector('#itemCBBlink').value,

                  ["Test Drive & Inspection Form"]: document.querySelector('#collapse' + rowIndex).querySelector('#switchTestDrive').value,
                  ["CarFax Attained"]: document.querySelector('#collapse' + rowIndex).querySelector('#switchCarFax').value,
                  ["Lien Search"]: document.querySelector('#collapse' + rowIndex).querySelector('#switchLienSearch').value,
                  ["Purchasing Agreement"]: document.querySelector('#collapse' + rowIndex).querySelector('#switchPurchasingAgreement').value,
                  ["Customer Signed Carfax & MFA"]: document.querySelector('#collapse' + rowIndex).querySelector('#switchCustomerSignedCarFaxMFA').value,
                  ["Proof of Payment"]: document.querySelector('#collapse' + rowIndex).querySelector('#switchProofOfPayment').value,
                  ["Lien Release Paid"]: document.querySelector('#collapse' + rowIndex).querySelector('#switchLienReleasePaid').value,
                  ["Keys & Registration Attained"]: document.querySelector('#collapse' + rowIndex).querySelector('#switchKeysAndRegAttained').value,
                  ["Registration Transferred to NBM"]: document.querySelector('#collapse' + rowIndex).querySelector('#switchRegistrationToNBM').value,
                  ["Transportation Coordinated & Invoiced"]: document.querySelector('#collapse' + rowIndex).querySelector('#switchTransportation').value,
                  ["Registration & Spare Keys Sent"]: document.querySelector('#collapse' + rowIndex).querySelector('#switchRegistrationSpareKeysSent').value,
                  ["Funding Requested & Confirmed"]: document.querySelector('#collapse' + rowIndex).querySelector('#switchFundingRequestedConfirmed').value,
                  ["Selling Agreement / Bill of Sale Signed"]: document.querySelector('#collapse' + rowIndex).querySelector('#switchSellingAgreement').value,
                  ["Converted to Inventory"]: document.querySelector('#collapse' + rowIndex).querySelector('#switchConvertedToInventory').value,
                  ["Invoice Sent / Sales Receipt Completed"]: document.querySelector('#collapse' + rowIndex).querySelector('#switchInvoiceSent').value,
                  ["Listed on Website"]: document.querySelector('#collapse' + rowIndex).querySelector('#switchListedOnWebsite').value,
                  ["7501 Attained"]: document.querySelector('#collapse' + rowIndex).querySelector('#switch7501Attained').value,
            })
        })
        .then( response => {
            if (response.ok) {
              setSaveButtonText("SAVED!")
            }
        })
        .catch( error => {
            console.log("Error: " + error)
        })
    }

    const fastHandler = async (invNum, status) => { 
    // function fastHandler(invNum, status) {
      if (status === "Pass") {
        setPassButtonText("PASS")
        // SetPassButtonDisabled("true")

        fetch(
        "https://sheet.best/api/sheets/e5dfe255-956d-4351-803f-392b559fd864/tabs/" + locationValue + "/INVNUM/*" + invNum,
        {
          method: "PATCH",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
            body: JSON.stringify({
            DEAL: status
          }),
        })
          .then( response => {
            if (response.ok) {
              setPassButtonText("PASS")
              // SetPassButtonDisabled("")
            }
          }
        )
      } else {

        fetch(
        "https://sheet.best/api/sheets/e5dfe255-956d-4351-803f-392b559fd864/tabs/" + locationValue + "/INVNUM/*" + invNum,
        {
            method: "PATCH",
            mode: "cors",
            headers: {
            "Content-Type": "application/json",
            },
                body: JSON.stringify({
                DEAL: status
            }),
        })
          .then( response => {
            if (response.ok) {

            }
          }
        )   
      }
    };

    function getLeadSourceIcon(source) {
      if (source === "Kijiji") {
        return "/static/kijiji.jpg"
      } else if (source === "Kijiji autos") {
        return "/static/kijiji-autos.png"
      } else if (source === "Facebook") {
        return "/static/facebook.png"
      } else {
        return "/static/network.png"
      }
    }

    function processLink(link) {
      if (link) {
        var str = link.substring(0, 4)
        if (str == 'http') {
         return link
        } else {
          return 'https://' + link
        }
      }
    }

    function createAccordionTitle(i) {
      return <span className="accordionTitle"><span className='leadNumber'><strong>{data[i]["INVNUM"]}</strong></span> {data[i]["LISTDATE"].slice(0,11)} {!data[i]["PURCHASEPRICE"] ? "ASK: " + formatCurrency(data[i]["ASKINGPRICE"]) : "PURCHASED: " + formatCurrency(data[i]["PURCHASEPRICE"]) } &nbsp;<strong><span className={data[i]["YEAR"] < 2012 ? 'red' : 'green'} > {data[i]["YEAR"]}</span>&nbsp; {data[i]["MAKE"]} {data[i]["MODEL"]} {data[i]["TRIM"]}</strong>&nbsp;-&nbsp;<span className={data[i]["KMs"] > 180000 ? "red" : "green"}>{data[i]["KMs"]} {!data[i]["KMs"] ? "" : "KMs"}</span>&nbsp;- {data[i]["VIN"]} - {data[i]["AGENT"]}</span>
    }

    return (
      <div className='page-container'>
        <Header />
        {!currentUser ? <Redirect to='/' /> : null }
        <div className='container'>
          <div className='section'>
            <div className='row'>
              <div className='col'>
                <form className='text-center'>
                  <div class="form-row justify-content-center">
                    <DropdownButton id="dropdown-location-button" title={locationValue} className="dropdown-buttons m-1"> 
                        {locationOptions.map((location) => <Dropdown.Item key={location} onClick={(e) => {
                          setLocationValue(e.target.textContent)
                          setFieldLocation(e.target.textContent)
                          getData()
                          window.location.reload(false)   
                        }}>{location}</Dropdown.Item> )}
                    </DropdownButton>
                    <DropdownButton id="dropdown-bookedstatus-button" title={statusValue} className="dropdown-buttons m-1">
                        {bookingOptions.map((option) => <Dropdown.Item key={option} onClick={(e) => {
                          setStatusValue(e.target.textContent)
                          setBookedStatus(e.target.textContent)
                          getData()
                          window.location.reload(false)
                        }}>{option}</Dropdown.Item> )}
                    </DropdownButton>
                    <input
                        id="agentSearch"
                        className='m-1'
                        onKeyPress={
                          (e) => { 
                            setAgentFilterCookie(e.target.value)
                            if (e.key === 'Enter') {
                              getData()
                              window.location.reload(false)     
                            }
                          } 
                        }
                        placeholder={currentAgent}
                    />
                    <input
                        id="vinSearch"
                        className='m-1'
                        onKeyPress={
                          (e) => { 
                            setVINFilterCookie(e.target.value)
                            if (e.key === 'Enter') {
                              getData()
                              window.location.reload(false)
                            }
                          }
                        }
                        type="text" 
                        placeholder={currentVIN}
                    />
                    <button id='clearFilterButton' className="btn btn-md btn-secondary m-1" onClick={(e) => { setAgentFilterCookie(""); setVINFilterCookie(""); getData(); window.location.reload(false); }}>Clear Filter</button>
                    <span className='counter-text'>Count: {data?.length}</span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion" id="accordionExample">
            {data?.map((item, i) => (
            <div key={i} className="accordion-item" id={`accordion-item${i}`}>
              <span className="accordion-header" id={`heading${i}`}>
                <div
                  className={!item.PURCHASEPRICE ? "accordion-button" : "accordion-button accordionOffered"}
                  type="button"
                  id={`accordion-button${i}`}
                >
                  <button
                    disabled={passButtonDisabled}
                    className="btn btn-md btn-danger"
                    onClick={() => { 
                      fastHandler(item.INVNUM, "Pass"); 
                      document.querySelector('#accordion-item' + i).toggleAttribute("hidden")
                    }}>
                    {passButtonText}
                  </button>
                    <span class='sourceIcon'>{!item["LISTINGLINK"] ? <img src={getLeadSourceIcon(item["SOURCE"])} /> : <a href={processLink(item["LISTINGLINK"])} target="_blank"><img src={getLeadSourceIcon(item["SOURCE"])} /></a>}</span>
                    <span
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${i}`}
                      aria-expanded="true"
                      aria-controls={`collapse${i}`}
                      onClick={() => {
                        document.querySelector('#accordion-button' + i).classList.toggle("accordionActive")
                      }}
                    >
                      {createAccordionTitle(i)}
                    </span>
                  <form className='float-right'>
                    <div class="form-row">
                      <input id="itemBuyerPrice" className="ml-2 mr-1" type="text" defaultValue={item["SENDTOBUYERPRICE"]} placeholder="Enter asking price..." />
                      <DropdownButton id="itemBuyer" title={sendToBuyersText} className="ml-1 mr-1"> 
                        {settingsBuyersNames.map((buyers) => 
                        <Dropdown.Item key={buyers} onClick={(e) => { sendToBuyers(i, e.target.textContent); }}>
                          {buyers}
                        </Dropdown.Item>)}
                      </DropdownButton>
                      <button className="btn btn-md btn-success ml-1 mr-2" onClick={(event) => { event.preventDefault(); saveData(i, item["INVNUM"]) }} >{saveButtonText}</button>
                    </div>
                  </form>
                </div>
              </span>
              <div
                  id={`collapse${i}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`heading${i}`}
                  data-bs-parent="#accordionExample"
              >
              {/* START BUTTONS */}
              <div className="accordion-body">
                <PricingTable data={data} key={i} index={i} item={item} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default ScraperLeads;