import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import UserActions from '../actions/UserActions'
import Button from 'react-bootstrap/Button'
import { withRouter } from 'react-router-dom'
import UserStore from '../stores/UserStore'
import UserApi from '../data/UserApi'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: ""
    }
  }

  componentDidMount() {
    //const currentUser = UserStore.getCurrentUser()
    const userId = UserStore.getCurrentUser()
    UserApi.getUserById(userId, (user)=>{
            this.setState({ user })
    })
  }

  render() {
    const currentUser = UserStore.getCurrentUser()
    return(
      <div>
          <Navbar className="bg-wasl-red" expand="lg" variant="dark">
              <Navbar.Brand href="/"><img height="30px" src="/static/header-logo.png" /></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link href="/">Home</Nav.Link>
                  {currentUser ? <Nav.Link href="/Inventory">Inventory</Nav.Link> : null}
                  {currentUser ? <Nav.Link href="/addNewProduct">Add to Inventory</Nav.Link> : null}
                  {currentUser ? <Nav.Link href="/AppSettings">Settings</Nav.Link> : null}
                </Nav>
                {currentUser ? <Navbar.Text><span className='m-3'><i>Hello,</i>&nbsp; 
                 <a href={`/userDetails/${this.state.user.id}`}>{this.state.user.firstName}&nbsp;</a></span></Navbar.Text> : null}
                {currentUser ? <Button className='signInButton' onClick={()=>{
                  UserActions.signoutUser()
                  this.props.history.push('/')
                  }}>Sign Out
                </Button> : null}
                {!currentUser ? <Button className='signInButton' onClick={()=>{
                  this.props.history.push('/signIn')
                  }}>Sign In
                </Button> : null}
              </Navbar.Collapse>
          </Navbar>
      </div>
  )
  }
}

export default withRouter(Header)