import _ from 'lodash';
import axios from 'axios'

let currentID = new Date().getTime();
const _clone = function(item) {
	return JSON.parse(JSON.stringify(item)); 
};

const baseUri = 'https://inventory.wholesalesolutions.ca:3001/'

export default class UserApi {
	static getAllUsers(cb) {
        axios.get(`${baseUri}users`)
        .then(response => cb(response.data))
		.catch(error => { throw error })
    }

	static saveUser(user) {
		currentID = currentID + 1
        user.id = currentID;
		axios.post(`${baseUri}users`, user)
		return _clone(user);
	}

	static deleteUser(id) {
		axios.delete(`${baseUri}users/${id}`);
	}

	static getUser(id, password, cb) {
        axios.get(`${baseUri}users?email=${id}&password=${password}`)
        .then(response => cb(response.data[0]))
        .catch(error => { throw error })
	}
	
	static getUserById(id, cb) {
		axios.get(`${baseUri}users?email=${id}`)
        .then(response => cb(response.data[0]))
		.catch(error => { throw error })
	}
}
