import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Header from '../includes/header'
import TopViewedProductsPage from './TopViewedProductsPage'

const AboutPage = () => {
    return(
        <div>
            <Header />
            <Container className="mt-4">
                <Row>
                    <Col>
                        <Card border="light" id="about">
                            <h1>Welcome to</h1>
                            <p>Wholesale Auto Solutions' Inventory Mangement System<br />
                            </p>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                      <div className='row justify-content-center'>
                        <Card style={{ width: '18rem' }} border="info">
                            <Card.Body>
                              <Card.Title>Welcome!</Card.Title>
                              <Card.Text>
                               Already a User?
                              </Card.Text>
                              <Button variant="primary" href="/signIn">Sign in</Button>
                              <Card.Text>
                               New User?
                              </Card.Text>
                              <Button variant="primary" href="#">Register</Button>
                            </Card.Body>
                        </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AboutPage